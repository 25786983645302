export const SliderData = [
    {
        image:"Dibujos/En-soi.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Jenny.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Sceau.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Zorba.jpg",
        alt: "large-vertical"
    },
    {
        image:"Dibujos/honestidad.jpg",
        alt: "large-horizontal"
    },
    {
        image:"Dibujos/IMG_0203.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/odalisca.JPG",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Mnemosyne.jpg",
        alt: "mnemosyne"
    }


];